<template>
  <header>
    <div class="logo">
      <router-link to="/"><img src="/zappala-logo.png" /></router-link>
    </div>
    <div class="flag">
      <router-link to="/"> <img src="/flag-of-calabria.png" /></router-link>
    </div>
    <div class="break"></div>
    <div><router-link to="/">Sersale Vital Records</router-link></div>
  </header>
  <div class="content">
    <router-view />
  </div>
  <footer>
    <p>Records indexed as of 5 July 2021: Death records, 1809-1826.</p>
    <p>Indexing by Daniel Zappala.</p>
    <p>
      Old handwriting can be a challenge to decipher. I have done my best to
      index each original record accurately. When clarification is needed, I
      double check the index, if available, at the end of a book of records, or
      cross-reference birth and death records if possible. I also check a
      [surname map](https://www.mappadeicognomi.it/index.php) if I see
      unfamiliar names. Please contact me if you would like to make corrections.
    </p>
    <p>
      Many thanks to those who have previously indexed some of the records for
      Sersale at [sersale.org](http://sersale.org/vital.htm). You helped me as a
      young genealogist and I hope to return that favor to others.
    </p>
  </footer>
</template>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  box-sizing: border-box;
}

header {
  font-family: "Chivo", sans-serif;
  font-weight: 500;
  font-size: 1.3em;
  margin-left: 10px;
  margin-top: 5px;
  padding: 0px 0px;
  padding-bottom: 45px;
  height: 20px;
  display: flex;
  flex-wrap: wrap;

  align-items: center;
}

footer {
  background-color: #eee;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
}

header a {
  text-decoration: none;
}

header img {
  margin-right: 10px;
  padding-right: 10px;
}

.logo img {
  height: 30px;
  border-right: 1px solid;
  padding-right: 30px;
  margin-right: 30px;
}

.flag img {
  height: 40px;
}

.break {
  flex-basis: 100%;
  height: 5px;
}

.pure-button-primary,
.pure-button-selected,
a.pure-button-primary,
a.pure-button-selected {
  background-color: #cfa617;
}

a,
a:visited {
  color: #025baa;
}

.pure-form legend {
  color: #025baa;
}

h1 {
  margin: 1em 0 0.25em 0;
}

h1:after,
h1:before {
  display: inline-block;
  content: "";
  border-top: 0.1rem solid black;
  width: 4rem;
  transform: translateY(-0.5rem);
}

h1:before {
  margin: 0 1rem 0 0;
}

h1:after {
  margin: 0 0 0 1rem;
}

@media only screen and (min-width: 850px) {
  header {
    margin-left: 100px;
    margin-top: 30px;
    padding: 30px 0px;
  }
  footer {
    padding-left: 100px;
  }
  .break {
    display: none;
  }
}
</style>
