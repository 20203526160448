<template>
  <div class="home">
    <div class="mainForm">
      <form class="pure-form" @submit.prevent="search">
        <fieldset>
          <div class="names">
            <div>
              <label>Given Name</label>
              <input v-model="given" class="nameInput" />
            </div>
            <div>
              <label>Surname</label>
              <input v-model="surname" class="nameInput" />
            </div>
          </div>
          <div class="double">
            <div class="half">
              <label>Start Year</label>
              <input v-model="startYear" class="yearInput" />
            </div>
            <div class="half">
              <label>End Year</label>
              <input v-model="endYear" class="yearInput" />
            </div>
          </div>
          <div class="double">
            <div class="half">
              <label>Start Age</label>
              <input v-model="startAge" class="yearInput" />
            </div>
            <div class="half">
              <label>End Age</label>
              <input v-model="endAge" class="yearInput" />
            </div>
          </div>
        </fieldset>
        <a href="#" @click="toggleAdvanced">Advanced</a>
        <fieldset class="advanced" v-if="advanced">
          <legend>Father</legend>
          <div class="double">
            <div class="halfName">
              <label>Given Name</label>
              <input v-model="fatherGiven" class="nameInput" />
            </div>
            <div class="halfName">
              <label>Surname</label>
              <input v-model="fatherSurname" class="nameInput" />
            </div>
          </div>
        </fieldset>
        <fieldset v-if="advanced" class="advanced">
          <legend>Mother</legend>
          <div class="double">
            <div class="halfName">
              <label>Given Name</label>
              <input v-model="motherGiven" class="nameInput" />
            </div>
            <div class="halfName">
              <label>Surname</label>
              <input v-model="motherSurname" class="nameInput" />
            </div>
          </div>
        </fieldset>
        <fieldset v-if="advanced" class="advanced">
          <legend>Spouse</legend>
          <div class="double">
            <div class="halfName">
              <label>Given Name</label>
              <input v-model="spouseGiven" class="nameInput" />
            </div>
            <div class="halfName">
              <label>Surname</label>
              <input v-model="spouseSurname" class="nameInput" />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <button type="submit" class="pure-button pure-button-primary">
            Search
          </button>
        </fieldset>
      </form>
    </div>
    <div class="content">
      <div class="spinner" v-if="pending"></div>
      <div v-if="searchAttempted && results.length === 0">No results</div>
      <div class="year" v-else v-for="year in sortedYears" :key="year">
        <h1>{{ year }}</h1>
        <div
          class="results"
          v-for="result in sortedResults[year]"
          :key="result.id"
        >
          <ul>
            <li>
              <span class="name">{{ result.given }} {{ result.surname }}</span>
            </li>
            <li>Died: {{ parseDate(result.date) }}</li>
            <li>Age: {{ result.age }} {{ result.duration }}</li>
            <li v-if="result.fatherGiven || result.fatherSurname">
              Father: {{ result.fatherGiven }} {{ result.fatherSurname }}
            </li>
            <li v-if="result.motherGiven || result.motherSurname">
              Mother: {{ result.motherGiven }} {{ result.motherSurname }}
            </li>
            <li v-if="result.spouseGiven || result.spouseSurname">
              Spouse: {{ result.spouseGiven }} {{ result.spouseSurname }}
            </li>
            <li>Part: {{ result.part }}, Number: {{ result.number }}</li>
            <li>
              Image:
              <a target="_blank" :href="result.url">{{ result.image }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      given: "",
      surname: "",
      startYear: 1809,
      endYear: 1826,
      startAge: 0,
      endAge: 120,
      fatherGiven: "",
      fatherSurname: "",
      motherGiven: "",
      motherSurname: "",
      spouseGiven: "",
      spouseSurname: "",
      advanced: false,
      results: [],
      pending: false,
      searchAttempted: false,
    };
  },
  computed: {
    sortedResults() {
      let resultByYear = {};
      this.results.forEach((result) => {
        if (!resultByYear[result.year]) resultByYear[result.year] = [];
        resultByYear[result.year].push(result);
      });
      return resultByYear;
    },
    sortedYears() {
      let years = [];
      this.results.forEach((result) => {
        if (!years.includes(result.year)) years.push(result.year);
      });
      return years.sort((a, b) => parseInt(a) - parseInt(b));
    },
  },
  methods: {
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    async search() {
      this.pending = true;
      try {
        const result = await axios.post("/api/v1/search", {
          given: this.given,
          surname: this.surname,
          startYear: this.startYear,
          endYear: this.endYear,
          startAge: this.startAge,
          endAge: this.endAge,
          fatherGiven: this.fatherGiven,
          fatherSurname: this.fatherSurname,
          motherGiven: this.motherGiven,
          motherSurname: this.motherSurname,
          spouseGiven: this.spouseGiven,
          spouseSurname: this.spouseSurname,
          comune: "Sersale",
          region: "Catanzaro",
          province: "Calabria",
        });
        this.pending = false;
        this.searchAttempted = true;
        this.results = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    parseDate(date) {
      return moment(date).utc().format("DD MMMM YYYY");
    },
  },
};
</script>

<style scoped>
.mainForm {
  background-color: #eee;
  padding: 10px 10px;
  margin-top: 20px;
}

.content {
  padding: 10px 10px;
}
input {
  margin: 0px 0px 10px 0px;
}

.nameInput {
  width: 100%;
}

.yearInput {
  width: 80px;
  margin-right: 30px;
}

.double {
  display: flex;
}

.halfName input {
  width: 90%;
}

.advanced {
  border: 1px #999 solid;
  padding: 5px;
  margin-top: 10px;
}

.advanced .double {
  margin-top: -10px;
  padding-left: 5px;
}

.advanced legend {
  margin: 0;
  margin-left: 1em;
  padding: 0.2em 0.8em;
  width: inherit;
  border: none;
}

.results {
  margin-top: 20px;
}

ul {
  list-style: none;
  margin: 10px 0px;
  padding: 0;
}

li {
  margin: 0;
}

.name {
  font-size: 1.1em;
  font-weight: bold;
  color: #d91d03;
}

@media only screen and (min-width: 850px) {
  .mainForm {
    background-color: #eee;
    padding: 30px 100px;
    margin-top: 20px;
  }

  .content {
    padding: 30px 100px;
  }
  label {
    display: block;
  }
  input {
    display: block;
    margin: 0px 30px 10px 0px;
  }

  .names {
    display: flex;
  }

  .nameInput {
    width: 350px;
  }

  .yearInput {
    width: 80px;
  }

  .half label {
    width: 80px;
  }

  .half input {
    margin: 0px 30px 20px 0px;
  }

  .halfName label {
    width: 350px;
  }

  .halfName input {
    width: 350px;
  }

  .advanced .double {
    margin-top: 0px;
  }

  .advanced input {
    margin-right: 20px;
  }

  .advanced label {
    margin-left: 10px;
  }

  button {
    margin-top: 20px;
  }
}
</style>
